body {
  margin: 0;
  font-size: 20px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Crimson Text";
  font-size: 75px;
  color: #ffffff;
  text-shadow: 2px 2px 4px #000;
  padding-bottom: 20px;
  font-weight: 400;
}

@media (max-width: 1100px) {
  h1 {
    font-size: 50px;
    font-weight: 400;
  }
}
