.footer {
  background: #000004;
  color: #fff;
  font-size: 0.7em;
  position: relative;
  top: 0;
  width: 100%;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  letter-spacing: 1.5px;
}

@media (max-width: 800px) {
  .footer {
    font-size: 0.58em;
    letter-spacing: 0.5px;
  }
}
