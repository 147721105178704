.welcome-container {
  position: relative;
  top: -5px;
  left: 0;
  right: 0;
  text-align: left;
  z-index: 2;
  background: #000004;
  padding: 80px 80px 0 100px;
  margin-bottom: -50px;
  color: #ffffff;
  min-height: 1000px;
}

.welcome-left-col {
  width: 40%;
  padding-bottom: 40px;
  font-size: 1.15em;
  font-weight: 600;
  z-index: 2;
  white-space: nowrap;
  position: relative;
  top: -50px;
}

.welcome-subheader {
  display: none;
}

.list-table {
  display: none;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 165px;
  left: 60%;
  text-shadow: 2px 2px 4px #000;
  font-size: 1.25em;
}

.welcome-icons {
  opacity: 0.5;
  color: #a3c2f9;
  font-size: 1.75em;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.term-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 140px;
  left: 60%;
  margin: 0 0 0 80px;
  text-shadow: 2px 2px 4px #000;
  font-size: 1.5em;
  font-weight: 600;
  font-family: "Crimson Text", Times, serif;
}

.term-row {
  display: flex;
  flex-direction: row;
  height: 0;
  margin-bottom: 38px;
}

@media (max-width: 1100px) {
  .welcome-container {
    min-height: 950px;
    text-align: center;
    padding: 80px 40px;
  }

  .welcome-left-col {
    font-size: 1em;
    white-space: wrap;
    line-height: 1.5em;
    text-align: center;
    width: 100%;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-shadow: 2px 2px 4px #000;
    font-size: 1.25em;
    margin-top: 110px;
    left: 35%;
  }

  .term-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 35%;
    top: 250px;
    margin: 0 0 0 80px;
    text-shadow: 2px 2px 4px #000;
    font-size: 1.5em;
    font-weight: 600;
    font-family: "Crimson Text", Times, serif;
  }
}

@media (max-width: 750px) {
  .welcome-container {
    min-height: 800px;
  }

  .welcome-left-col {
    display: none;
  }

  .welcome-subheader {
    display: block;
    font-weight: 600;
    position: relative;
    top: -50px;
    line-height: 1.7em;
  }

  .icon-container {
    display: none;
  }

  .term-list {
    display: none;
  }

  .list-table {
    display: inline-block;
    position: relative;
    top: -30px;
    margin-left: auto;
    margin-right: auto;
  }

  .icon-column {
    text-align: center;
  }

  .welcome-icons {
    display: block;
  }

  .text-column {
    text-align: left;
    padding-left: 20px;
    text-shadow: 2px 2px 4px #000;
    font-size: 1.2em;
    font-weight: 600;
    font-family: "Crimson Text", Times, serif;
    position: relative;
    top: -13px;
  }
}
