.about-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
  z-index: 2;
  background: #021965;
  padding: 80px;
  height: fit-content;
}

.about-title {
  padding-left: 20px;
}

.bio-block {
  position: relative;
  top: -130px;
  width: 50%;
  left: 40%;
  right: 10%;
  color: #ffffff;
  font-family: "Crimson Text", Times, serif;
  font-size: 1.5em;
  margin-bottom: -60px;
}

@media (max-width: 1100px) {
  .about-title {
    text-align: center;
    padding: 0;
  }

  .bio-block {
    position: relative;
    top: 20px;
    width: 85%;
    left: 10%;
    right: 10%;
    padding-bottom: 150px;
  }
}

@media (max-width: 950px) {
  .about-title {
    text-align: center;
  }

  .bio-block {
    font-size: 1.1em;
  }
}

@media (max-width: 600px) {
  .about-container {
    padding: 80px 40px;
  }
}
