.mainbg {
  object-fit: cover;
  height: 100vh;
  width: 100%;
  z-index: 1;
}

.logo-container {
  position: absolute;
  z-index: 2;
  top: -60px;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 2px 4px #000;
}

.logo-name {
  font-weight: 600;
  font-size: 100px;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px #000;
}

.logo-icon {
  color: #4e95c4;
  opacity: 0.8;
  padding: 0 8px 0 6px;
}

.logo-sub-text {
  font-family: "Crimson Text", Times, serif;
  font-size: 35px;
  letter-spacing: 4.7px;
  margin-left: 6px;
  position: relative;
  top: -110px;
}

.down-arrow {
  font-size: 50px;
  color: #ffffff;
  position: absolute;
  bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  z-index: 2;
  text-shadow: 2px 2px 4px #000;
}

.scroll {
  color: #ffffff;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  z-index: 2;
  text-shadow: 2px 2px 4px #000;
  font-size: 11px;
  bottom: 8px;
}

@media (max-width: 1100px) {
  .mainbg {
    object-fit: cover;
    height: 100vh;
    z-index: 1;
  }
}

@media (max-width: 800px) {
  .logo-name {
    font-weight: 600;
    font-size: 50px;
    margin-top: 100px;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px #000;
  }

  .logo-icon {
    color: #4e95c4;
    opacity: 0.8;
    padding: 0 8px 0 6px;
  }

  .logo-sub-text {
    font-family: "Crimson Text", Times, serif;
    font-size: 20px;
    letter-spacing: 1.6px;
    margin-left: 6px;
    position: relative;
    top: -50px;
  }
}
