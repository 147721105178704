.carousel-container {
  margin-top: 50px;
}

.ooa {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.oosingle {
  display: none;
}

.billsall {
  margin-top: -30px;
}

.billsingle {
  display: none;
}

.oportun {
  width: 17%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.dysiall {
  width: 37%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -40px;
}

.dysisingle {
  display: none;
}

.paintings {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
}

.blurbbg {
  margin-top: 180px;
}

@media (max-width: 1100px) {
  .blurbbg {
    margin-top: 250px;
  }
}

.blurb {
  opacity: 1 !important;
  background: #000004 !important;
  font-size: 0.8em !important;
  text-align: left !important;
  padding: 20px 100px !important;
}

.blurbtitle {
  font-weight: 800;
}

.blurbtext {
  display: block;
  font-weight: 500;
  margin-top: 7px;
  line-height: 1.4em;
}

.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.control-arrow {
  color: #999 !important;
  opacity: 0.7 !important;
  -webkit-transition: all 0.1s ease-in !important;
  -moz-transition: all 0.1s ease-in !important;
  -ms-transition: all 0.1s ease-in !important;
  -o-transition: all 0.1s ease-in !important;
  transition: all 0.1s ease-in !important;
  height: 3em;
  margin-top: 16% !important;
}

.carousel .control-disabled.control-arrow {
  cursor: not-allowed !important;
  display: inline-block !important;
  opacity: 0.25 !important;
  color: #999 !important;
}

.carousel .control-disabled.control-arrow:hover {
  cursor: not-allowed !important;
  background: none !important;
}

.carousel .control-disabled.control-next.control-arrow:hover {
  cursor: not-allowed !important;
  background: none !important;
  opacity: 0.25 !important;
  color: #999 !important;
}

.carousel .control-disabled.control-prev.control-arrow:hover {
  cursor: not-allowed !important;
  background: none !important;
  opacity: 0.25 !important;
  color: #999 !important;
}

.carousel .control-next.control-arrow {
  margin-right: 8% !important;
}

.carousel .control-next.control-arrow:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f054";
  font-size: 3em;
  border: none !important;
}

.carousel .control-next.control-arrow:hover {
  background: none !important;
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f054";
  color: #1b64ea !important;
  border: none !important;
  opacity: 1 !important;
}

.carousel .control-prev.control-arrow {
  margin-left: 8% !important;
}

.carousel .control-prev.control-arrow:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f053";
  font-size: 3em;
  border: none !important;
}

.carousel .control-prev.control-arrow:hover {
  background: none !important;
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f054";
  color: #1b64ea !important;
  border: none !important;
  opacity: 1 !important;
}

@media (max-width: 800px) {
  .blurbbg {
    margin-top: 250px;
  }

  .blurb {
    padding: 30px !important;
  }

  .ooa {
    display: none;
  }

  .oosingle {
    display: block;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .billsingle {
    display: block;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .oportun {
    width: 45%;
    margin-top: 50px;
  }

  .dysiall {
    display: none;
  }

  .dysisingle {
    display: block;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .paintings {
    width: 62%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .control-arrow {
    color: #999 !important;
    opacity: 0.9 !important;
    margin-top: 40% !important;
  }

  .carousel .control-next.control-arrow {
    margin-right: 1% !important;
    z-index: 10;
  }

  .carousel .control-prev.control-arrow {
    margin-left: 1% !important;
  }

  .carousel .control-next.control-arrow:before {
    font-size: 2.5em !important;
  }

  .carousel .control-prev.control-arrow:before {
    font-size: 2.5em !important;
  }

  .carousel .control-disabled.control-arrow {
    opacity: 0.2 !important;
  }

  .carousel .control-disabled.control-next.control-arrow:hover {
    opacity: 0.2 !important;
  }

  .carousel .control-disabled.control-prev.control-arrow:hover {
    opacity: 0.2 !important;
  }

  .carousel .control-next.control-arrow:hover {
    color: #999 !important;
  }

  .carousel .control-prev.control-arrow:hover {
    color: #999 !important;
  }
}
