.contact-block {
  background: #525f79;
  height: 85vh;
  position: relative;
  top: 0;
  z-index: 10;
  padding: 120px 80px 20px 100px;
}

.contact-link-block {
  position: relative;
  top: 10%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.primarybtn {
  background: #4b8afd;
  box-shadow: 0 2px 4px #29526d;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: 2px solid #4b8afd;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.primarybtn:hover {
  background: #1b64ea;
  color: #fff;
  text-decoration: none;
  border: 2px solid #1b64ea;
}

.primarybtn-icon {
  font-size: 32px;
  margin-right: 17px;
  position: relative;
  top: 3px;
}

.linkedin-btn {
  padding: 20px 70px;
  position: relative;
  top: 0;
}

.email-btn {
  padding: 20px 65px;
  position: relative;
  top: 130px;
  margin-bottom: 50px;
}

@media (max-width: 750px) {
  .contact-block {
    padding: 40px 0;
  }
  .contact-title {
    text-align: center;
    white-space: nowrap;
  }
}
