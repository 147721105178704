.highlights-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
  z-index: 2;
  background: #ffffff;
  padding: 80px;
  height: fit-content;
}

.highlights-title {
  font-family: "Crimson Text";
  font-size: 75px;
  color: #525f79;
  text-shadow: 1px 1px 2px #ccc;
  margin-top: 50px;
  padding-bottom: 20px;
  font-weight: 400;
}

@media (max-width: 950px) {
  .highlights-container {
    padding: 40px 80px 80px 80px;
  }

  .highlights-title {
    text-align: center;
    white-space: nowrap;
    font-size: 60px;
  }
}

@media (max-width: 600px) {
  .highlights-container {
    padding: 40px 0 60px 0;
  }

  .highlights-title {
    text-align: center;
    white-space: nowrap;
    font-size: 45px;
  }
}
